import React from "react";

function success() {
  return (
    <div className="suc-body">
      <div className="suc-card">
        <div className="suc-card-icon">
          <i className="checkmark-error">!</i>
        </div>
        <h1>Error</h1>
        <p>
          Your payment was unsuccessful
          <br /> Please try again!
        </p>
      </div>
    </div>
  );
}

export default success;
